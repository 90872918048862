var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"userForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
var pristine = ref.pristine;
return [_c('VsLabel',{attrs:{"size":"large"}},[_vm._v(_vm._s(_vm.$t('account.myAccount.userData')))]),_c('div',{staticClass:"md:vs-flex vs-gap-4 vs-mb-4"},[_c('ValidationProvider',{attrs:{"vid":"mainName","name":_vm.$t('account.myAccount.errorName.name'),"slim":"","rules":"required|alphaSpaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsInput',{staticClass:"vs-flex-1 vs-mb-4 md:vs-mb-0",attrs:{"placeholder":_vm.$t('account.myAccount.errorName.name'),"error":errors.length > 0,"errorMessage":errors[0],"size":"large","labelHidden":true},model:{value:(_vm.firstname),callback:function ($$v) {_vm.firstname=$$v},expression:"firstname"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"mainSurname","name":_vm.$t('account.myAccount.errorName.lastname'),"slim":"","rules":"required|alphaSpaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsInput',{staticClass:"vs-flex-1",attrs:{"placeholder":_vm.$t('account.myAccount.errorName.lastname'),"size":"large","error":errors.length > 0,"errorMessage":errors[0],"labelHidden":true},model:{value:(_vm.lastname),callback:function ($$v) {_vm.lastname=$$v},expression:"lastname"}})]}}],null,true)})],1),_c('div',{staticClass:"md:vs-flex vs-gap-4 vs-mb-10"},[_c('ValidationProvider',{attrs:{"vid":"mainEmail","name":_vm.$t('account.myAccount.errorName.email'),"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsInput',{staticClass:"vs-flex-1 vs-mb-4 md:vs-mb-0",attrs:{"placeholder":_vm.$t('account.myAccount.errorName.email'),"size":"large","error":errors.length > 0,"errorMessage":errors[0],"labelHidden":true},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"mainPhone","name":_vm.$t('account.myAccount.errorName.phone'),"slim":"","rules":"required|libphoneNumber"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsPhoneInput',{staticClass:"vs-flex-1",attrs:{"placeholder":_vm.$t('account.myAccount.errorName.phone'),"size":"large","error":errors.length > 0,"errorMessage":errors[0],"labelHidden":true},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})]}}],null,true)})],1),_c('div',{staticClass:"vs-mb-10"},[_c('VsButton',{attrs:{"disabled":pristine,"loading":_vm.loading},on:{"click":function($event){return passes(_vm.submitForm)}}},[_vm._v(" "+_vm._s(_vm.$t('account.myAccount.save'))+" ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }