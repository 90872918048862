
























































































import { Component, Vue } from 'vue-property-decorator'
import { UserModule } from '@/store/modules/user'
import {
    updateUser,
    me,
} from '@/api/userapi/users'

import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { get } from 'lodash'
import { AppModule } from '@/store/modules/app'

@Component({
    name: 'MyAccount',
})
export default class extends Vue {
    private email = ''
    private phone = ''
    private firstname = ''
    private lastname = ''
    private loading = false
    $refs: any

    beforeMount () {
        this.email = get(this.user, 'email', '')
        this.phone = get(this.user, 'phone', '')
        this.firstname = get(this.user, 'firstname', '')
        this.lastname = get(this.user, 'lastname', '')
    }

    get user () {
        return UserModule.user
    }

    set user (val) {
        UserModule.SET_USER(val)
    }

    get is4Dem () {
        return get(AppModule.consoleConf, 'is4Dem', false)
    }

    private async submitForm () {
        if (this.loading) return
        this.loading = true
        const data = {
            firstname: this.firstname,
            lastname: this.lastname,
            email: this.email,
            phone: this.phone,
        }
        try {
            await updateUser(data)
            const resp = await me()
            this.user = resp.data
            this.$root.$vsToast({
                heading: this.$t('account.myAccount.successMessages.dataSavedSuccessfully'),
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
            this.$refs.userForm.reset()
        } catch (e) {
            console.log(e)
            const errorCode = get(e, 'response.data.message', '')
            switch (errorCode) {
                case 'EmailNotValid':
                    this.$refs.userForm.setErrors({
                        mainEmail: this.$t('account.myAccount.errorMessages.emailNotValid'),
                    })
                    break
                default:
                    this.$root.$vsToast({
                        heading: this.$t('account.myAccount.errorMessages.errorDuringSavingdata'),
                        aspect: VsToastAspectEnum.alert,
                        timeout: 3000,
                    })
            }
        }
        this.loading = false
    }
}
